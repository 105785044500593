export default {
  data: () => ({
    currentPage: 1,
    perPage: 20,
  }),
  methods: {
    goToPage(e) {
      if (e) {
        this.currentPage = e;
      }
    },
    setItemsPerPage(e) {
      if (e) {
        this.perPage = e;
      }
    },
  },
};
