<template>
  <div class="table-pagination">
    <div class="pagination-selector">
      <b-pagination
        v-model="currentPage"
        :total-rows="length"
        :per-page="perPage"
        limit="5"
        first-number
        last-number
      >
        <template #prev-text
          ><svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 14L5 8L11 2"
              stroke="#F2F2F2"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>
        <template #next-text
          ><svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 2L11 8L5 14"
              stroke="#F2F2F2"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>
      </b-pagination>
    </div>
    <div class="per-page-selector">
      <div class="per-page-title">
        {{ $t('buttonLabels.showPerPage') }}
      </div>
      <b-form-group>
        <b-form-radio-group
          id="btn-radios-1"
          v-model="perPage"
          :options="options"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TablePagination',
  props: {
    length: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    currentPage: 1,
    options: [
      { text: '20', value: 20 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
    ],
    perPage: 20,
  }),
  watch: {
    currentPage: {
      handler(value) {
        this.$emit('goToPage', value);
      },
    },
    perPage: {
      handler(value) {
        this.$emit('setPerPage', value);
      },
    },
    length: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>
