<template>
  <div class="date-dropdown" v-click-outside="closeDropdown">
    <button
      class="filter-button"
      @click="toggleDropdown"
      :class="{ disabled: isDisabled }"
    >
      <button class="filter-clear" v-if="dateRange" @click.stop="clearSelectedDates">
        <ClearFilterIcon />
      </button>
      {{ title }}:
      {{ dateRange ? formatDates(dateRange.startDate, dateRange.endDate) : $t('label.all') }}
      <ArrowDownYellow :class="{ flip: showDropdown }" />
    </button>
    <div class="date-dropdown__dropdown" :class="{ show: showDropdown }">
      <div class="date-input" id="datepicker-trigger">
        {{ dateOne && dateTwo ? formatDates(dateOne, dateTwo) : subTitle }}
        <CalendarIcon />
      </div>
      <div class="date-picker">
        <AirbnbStyleDatepicker
          trigger-element-id="datepicker-trigger"
          mode="range"
          :date-one="dateOne"
          :date-two="dateTwo"
          :showShortcutsMenuTrigger="false"
          :showActionButtons="false"
          inline
          :monthsToShow="1"
          @date-one-selected="dateOne = $event"
          @date-two-selected="dateTwo = $event"
        >
          <template #previous-month-icon>
            <PreviousMonthArrow />
          </template>
          <template #next-month-icon>
            <NextMonthArrow />
          </template>
        </AirbnbStyleDatepicker>
        <div class="apply-button-container">
          <AddButton size="small" :title="$t('buttonLabels.select2')" @click="selectDates" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import format from 'date-fns/format';
import ArrowDownYellow from '../Icons/ArrowDownYellow.vue';
import CalendarIcon from '../Icons/CalendarIcon.vue';
import AddButton from '../Buttons/AddButton.vue';
import ClearFilterIcon from '../Icons/ClearFilterIcon.vue';
import PreviousMonthArrow from '../Icons/PreviousMonthArrow.vue';
import NextMonthArrow from '../Icons/NextMonthArrow.vue';

export default {
  name: 'DateDropdown',
  components: {
    ArrowDownYellow,
    CalendarIcon,
    AddButton,
    ClearFilterIcon,
    PreviousMonthArrow,
    NextMonthArrow,
  },
  props: {
    clearTrigger: {
      type: Number,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showDropdown: false,
    dateOne: '',
    dateTwo: '',
    dateRange: null,
    dateFormat: 'DD/MM/YY',
  }),
  methods: {
    toggleDropdown() {
      if (!this.isDisabled) this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = '';
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo && dateTwo !== dateOne) {
        formattedDates += ` ${this.$t('supportText.to2')} ${format(dateTwo, this.dateFormat)}`;
      }
      return formattedDates;
    },
    selectDates() {
      if (this.dateTwo && this.dateTwo) {
        this.dateRange = { startDate: this.dateOne, endDate: this.dateTwo };
        this.updateFilter();
      }
      this.showDropdown = false;
    },
    clearSelectedDates() {
      this.dateOne = '';
      this.dateTwo = '';
      this.dateRange = null;
      this.updateFilter();
    },
    updateFilter() {
      this.$emit('updateFilter', { dateRange: this.dateRange });
    },
  },
  watch: {
    clearTrigger() {
      if (this.dateRange) {
        this.clearSelectedDates();
      }
    },
  },
};
</script>
