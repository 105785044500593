<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.45898 1C4.09655 1 3.80273 1.29381 3.80273
      1.65625V1.93311H3.13333C1.95513 1.93311 1 2.88823
      1 4.06644V12.8664C1 14.0446 1.95513 14.9998 3.13333
      14.9998H12.8667C14.0449 14.9998 15 14.0446 15
      12.8664V4.06644C15 2.88823 14.0449 1.93311 12.8667
      1.93311H12.2V1.65625C12.2 1.29381 11.9062 1 11.5438
      1C11.1814 1 10.8875 1.29381 10.8875
      1.65625V1.93311H5.11523V1.65625C5.11523 1.29381 4.82142
      1 4.45898 1ZM10.8875 3.61042V3.23311H5.11523V3.61042C5.11523
      3.97285 4.82142 4.26667 4.45898 4.26667C4.09655 4.26667 3.80273
      3.97285 3.80273 3.61042V3.23311H3.13333C2.67309 3.23311 2.3 3.6062
      2.3 4.06644V12.8664C2.3 13.3267 2.6731 13.6998 3.13333
      13.6998H12.8667C13.3269 13.6998 13.7 13.3267 13.7 12.8664V4.06644C13.7
      3.6062 13.3269 3.23311 12.8667 3.23311H12.2V3.61042C12.2 3.97285 11.9062
      4.26667 11.5438 4.26667C11.1814 4.26667 10.8875 3.97285 10.8875 3.61042ZM4.92091
      6.97219C5.28174 6.97219 5.57424 6.67969 5.57424 6.31886C5.57424
      5.95803 5.28174 5.66553 4.92091 5.66553C4.56009 5.66553 4.26758 5.95803
      4.26758 6.31886C4.26758 6.67969 4.56009 6.97219 4.92091 6.97219ZM5.57424
      8.88576C5.57424 9.24658 5.28174 9.53909 4.92091 9.53909C4.56009 9.53909
      4.26758 9.24658 4.26758 8.88576C4.26758 8.52493 4.56009 8.23242 4.92091
      8.23242C5.28174 8.23242 5.57424 8.52493 5.57424 8.88576ZM4.92091 12.106C5.28174
      12.106 5.57424 11.8135 5.57424 11.4527C5.57424 11.0918 5.28174 10.7993 4.92091
      10.7993C4.56009 10.7993 4.26758 11.0918 4.26758 11.4527C4.26758 11.8135 4.56009
      12.106 4.92091 12.106ZM8.6094 6.31886C8.6094 6.67969 8.31689 6.97219 7.95607
      6.97219C7.59524 6.97219 7.30273 6.67969 7.30273 6.31886C7.30273 5.95803 7.59524
      5.66553 7.95607 5.66553C8.31689 5.66553 8.6094 5.95803 8.6094 6.31886ZM7.95607
      9.53909C8.31689 9.53909 8.6094 9.24658 8.6094 8.88576C8.6094 8.52493 8.31689
      8.23242 7.95607 8.23242C7.59524 8.23242 7.30273 8.52493 7.30273 8.88576C7.30273
      9.24658 7.59524 9.53909 7.95607 9.53909ZM8.6094 11.4527C8.6094 11.8135 8.31689
      12.106 7.95607 12.106C7.59524 12.106 7.30273 11.8135 7.30273 11.4527C7.30273
      11.0918 7.59524 10.7993 7.95607 10.7993C8.31689 10.7993 8.6094 11.0918 8.6094
      11.4527ZM10.9844 6.97219C11.3452 6.97219 11.6377 6.67969 11.6377 6.31886C11.6377
      5.95803 11.3452 5.66553 10.9844 5.66553C10.6236 5.66553 10.3311 5.95803 10.3311
      6.31886C10.3311 6.67969 10.6236 6.97219 10.9844 6.97219ZM11.6377 8.88576C11.6377
      9.24658 11.3452 9.53909 10.9844 9.53909C10.6236 9.53909 10.3311 9.24658 10.3311
      8.88576C10.3311 8.52493 10.6236 8.23242 10.9844 8.23242C11.3452 8.23242 11.6377
      8.52493 11.6377 8.88576ZM10.9844 12.106C11.3452 12.106 11.6377 11.8135 11.6377
      11.4527C11.6377 11.0918 11.3452 10.7993 10.9844 10.7993C10.6236 10.7993 10.3311
      11.0918 10.3311 11.4527C10.3311 11.8135 10.6236 12.106 10.9844 12.106Z"
      fill="#F2F2F2"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
};
</script>
